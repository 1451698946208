import React from 'react';
import { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import {
  Modal,
  Box,
  Grid,
  Typography,
  Button,
  IconButton,
  CircularProgress,
  Link,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    maxWidth: '90%',
  },
  image: {
    width: '100%',
    height: 'auto',
    maxWidth: '400px', // Set a maximum width for the image
    borderRadius: '5%/4%',
  },
  link: {
    color: 'inherit',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const FoilPill = ({ finish }) => {
  let badge = null
  if (finish == "Foil") {
    badge =
      <>
        {" · "}
        <span style={{
          backgroundImage: "linear-gradient(135deg, rgba(222,197,37,0) 0%, rgba(222,197,37,0) 1%, rgba(255,8,8,0.06) 19%, rgba(239,111,23,0.1) 32%, rgba(222,213,37,0.23) 45%, rgba(36,222,101,0.39) 62%, rgba(47,221,109,0.4) 63%, rgba(216,208,239,0.39) 79%, rgba(131,123,173,0.38) 88%, rgba(136,129,178,0.38) 89%, rgba(193,191,234,0) 100%)",
        }} className="badge badge-label">
          Foil
        </span>
      </>
  } else if (finish == "Etched") {
    badge =
      <>
        {" · "}
        <span style={{
          background: "linear-gradient(135deg, #FCF6BA, #FBF5B7, #d49d3b)"
        }} className="badge badge-label">
          Etched
        </span>
      </>
  } else {
    badge = <> </>
  }
  return badge
}

const PreorderPill = ({ preorder }) => {
  let badge = null
  if (preorder) {
    badge =
      <>
        {" · "}
        <span className="badge badge-info">
          Preorder
        </span>
      </>
  } else {
    badge = <> </>
  }
  return badge
}

const DesktopLink = ({ link, name, open, handlePopoverOpen, handlePopoverClose }) => {
  let html = <a
        href={link}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <span className="card-name">
          {name}
        </span>
      </a>
    return html
}

const MobileLink = ({ link, name, open, handlePopoverOpen, handlePopoverClose }) => {
  let html = <a
        href={link}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <span className="card-name">
          {name}
        </span>
      </a>
    return html
}

export default function MouseOverPopover({ name, link, image, finish, preorder = false, display = "block" }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
    setOpenModal(false);
    setImageLoaded(false);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div style={{display: display}}>
      {!isMobile && (<DesktopLink {...{ link, name, open, handlePopoverOpen, handlePopoverClose }} />)}
      {isMobile && (
        <Link onClick={() => setOpenModal(true)}
          style={{ color: '#007bff'}}
          >
          <span className="card-name">{name}</span>
        </Link>
      )}
      <span className="finish">
        <FoilPill {...{ finish }} />
      </span>
      <span>
        <PreorderPill {...{ preorder }} />
      </span>

      {!isMobile && (
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <div className={finish == "Foil" ? 'pickgradient' : 'non-foil'}>
            <img className="magic-card-rounded-corners popover-image" src={image} alt={name} />
          </div>
        </Popover>
      )}

      {isMobile && (
        <Modal open={openModal} onClose={handleClose} className={classes.modal}>

          <Grid container spacing={2} direction="column" alignItems="center">
            <div className={classes.modalContent}>
              <Grid item>
                <div className={classes.imageContainer}>
                  {!imageLoaded && <CircularProgress />}
                  <div className={finish == "Foil" ? 'pickgradient' : 'non-foil'}>
                    <img
                      className={classes.image}
                      src={image}
                      alt={name}
                      onLoad={() => setImageLoaded(true)}
                      style={{ display: imageLoaded ? 'block' : 'none' }}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item>
              <Button
                variant="contained"
                color="primary"
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Card Page
              </Button>
              <IconButton
                edge="end"
                color="secondary"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              </Grid>
            </div>
            </Grid>
        </Modal>
      )}
    </div>
  );
}
