import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const AuspostCsvBuilderForm = ({ addressLookupApiEndpoint, emailLookupApiEndpoint, buildApiEndpoint, request_param_data}) => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  const [formDataArray, setFormDataArray] = useState(() => {
    const savedData = localStorage.getItem('auspostCsvBuilderFormDataArray');
    return savedData ? JSON.parse(savedData) : [];
  });

  const [formData, setFormData] = useState({
    orderNumber: "",
    name: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    postcode: "",
    quantityOfCards: "",
    shippingType: "registered_post",
    email: "",
  });

  const [orderNumber, setOrderNumber] = useState(request_param_data.order_number || "");
  const [email, setEmail] = useState(request_param_data.email || "");
  const [resetButtonText, setResetButtonText] = useState("Clear All CSV Data");
  const [resetButtonColor, setResetButtonColor] = useState('#b28900')

  useEffect(() => {
    if (orderNumber) {
      fetchOrderData(orderNumber);
    }

    if (email && !orderNumber) {
      fetchEmailData(email);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('auspostCsvBuilderFormDataArray', JSON.stringify(formDataArray));
  }, [formDataArray]);

  const loadForm = (data) => {
    setFormData(data);
    setOrderNumber(data.orderNumber);
    setEmail(data.email);
  };

  const fetchEmailData = (email) => {
    axios.post(emailLookupApiEndpoint, { email }, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken, // Ensure CSRF token is sent
      },
    })
    .then((response) => {
      loadForm(response.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 404) {
          alert('Email not found.');
        } else {
          alert(`Error fetching email data: ${error.response.data.message || error.response.statusText}`);
        }
      }
    })
  };

  const handleOrderNumberKeyDown = (e) => {
    if (e.key === "Enter" && orderNumber) {
      e.preventDefault();
      setEmail("");
      fetchOrderData(orderNumber);
    }
  };

  const handleEmailKeyDown = (e) => {
    if (e.key === "Enter" && email) {
      e.preventDefault();
      setOrderNumber("");
      fetchEmailData(email);
    }
  };

  const fetchOrderData = (orderNumber) => {
    axios.get(`${addressLookupApiEndpoint}/${orderNumber}`)
      .then((response) => {
        loadForm(response.data);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            alert('Order not found.');
          } else {
            alert(`Error fetching order data: ${error.response.data.message || error.response.statusText}`);
          }
        }
      })
  };

  const handleOrderNumberChange = (e) => {
    const value = e.target.value;
    setOrderNumber(value);
    if (value) {
      fetchOrderData(value);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const orderNumberInputRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormDataArray((prevData) => [...prevData, formData]);
    setFormData({
      name: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
      postcode: "",
      quantityOfCards: "",
      shippingType: "registered_post",
      email: "",
    });
    setOrderNumber("");
    setEmail("");
    orderNumberInputRef.current.focus();
  };

  const handleAddressClick = (address, index) => {
    loadForm(address);
    setFormDataArray((prevData) => prevData.filter((_, i) => i !== index));
  };

  const fileInputRef = useRef(null);

  const handleBuildCsv = async () => {
    try {
      const response = await axios.post(buildApiEndpoint, formDataArray, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken,
        },
      });

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const link = document.createElement('a');
        const filename = `parcel-send-export-${new Date().toISOString().slice(0, 10)}.csv`;
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      } else {
        const errorData = response.data;
        alert(`Error: ${errorData.message}`);
      }
    } catch (error) {
      alert(`Error: ${error}`);
    }
  };

  const clearCsv = () => {
    if (resetButtonText === "Confirm") {
      setFormDataArray([]);
      setResetButtonText("Clear All CSV Data");
      setResetButtonColor("#b28900");
    }
    if (resetButtonText === "Clear All CSV Data") {
      setResetButtonText("Confirm");
      setResetButtonColor("#d32f2f");
    }
  };

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} md={6}>
        <Box
          component="form"
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "100%",
          }}
          onSubmit={handleSubmit}
        >
          <Typography variant="h5" gutterBottom>
            Auspost CSV Builder
          </Typography>
          <TextField
            label="Order Number"
            value={orderNumber}
            onChange={(e) => setOrderNumber(e.target.value)}
            onKeyDown={handleOrderNumberKeyDown}
            ref={orderNumberInputRef}
            fullWidth
          />
          <TextField
            label="Email Address"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleEmailKeyDown}
            fullWidth
          />
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            fullWidth
            required
          />
          <TextField
            label="Address Line 1"
            name="addressLine1"
            value={formData.addressLine1}
            onChange={(e) => setFormData({ ...formData, addressLine1: e.target.value })}
            fullWidth
            required
          />
          <TextField
            label="Address Line 2"
            name="addressLine2"
            value={formData.addressLine2}
            onChange={(e) => setFormData({ ...formData, addressLine2: e.target.value })}
            fullWidth
          />
          <TextField
            label="City"
            name="city"
            value={formData.city}
            onChange={(e) => setFormData({ ...formData, city: e.target.value })}
            fullWidth
            required
          />
          <TextField
            label="State"
            name="state"
            value={formData.state}
            onChange={(e) => setFormData({ ...formData, state: e.target.value })}
            fullWidth
            required
          />
          <TextField
            label="Country"
            name="country"
            value={formData.country}
            onChange={(e) => setFormData({ ...formData, country: e.target.value })}
            fullWidth
            required
          />
          <TextField
            label="Postcode"
            name="postcode"
            value={formData.postcode}
            onChange={(e) => setFormData({ ...formData, postcode: e.target.value })}
            fullWidth
            required
          />
          <TextField
            label="Quantity of Cards"
            name="quantityOfCards"
            type="number"
            value={formData.quantityOfCards}
            onChange={handleChange}
            fullWidth
            required
          />
          <FormControl fullWidth>
            <InputLabel>Shipping Type</InputLabel>
            <Select
              label="Shipping Type"
              name="shippingType"
              value={formData.shippingType}
              onChange={handleChange}
            >
              <MenuItem value="registered_post">Registered</MenuItem>
              <MenuItem value="express">Express</MenuItem>
            </Select>
          </FormControl>
          <Box mt={2}>
            <Button variant="contained" type="submit">
              Add Address to CSV
            </Button>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box mt={2}>
          <Typography variant="h5" gutterBottom>
            Saved Addresses
          </Typography>
        </Box>

        <Paper sx={{ paddingTop: 2 }}>
          <List>
            {formDataArray.map((address, index) => (
              <Grid item key={index}>
                <ListItem
                  button
                  onClick={() => handleAddressClick(address, index)}
                >
                  <ListItemText
                    primary={`${address.name} (${address.email})`}
                    secondary={
                      <>
                      {address.addressLine1}, {address.addressLine2 ? `${address.addressLine2}, ` : ''}{address.city}, {address.state} {address.postcode}
                      <br />
                      {address.quantityOfCards} cards, {address.shippingType}
                      </>
                    }
                  />
                  <IconButton edge="end">
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              </Grid>
            ))}
          </List>
        </Paper>
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleBuildCsv}>
            Build CSV
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: resetButtonColor, color: '#fff', marginLeft: 4 }} // Custom red button
            onClick={clearCsv}
          >
            {resetButtonText}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AuspostCsvBuilderForm;
